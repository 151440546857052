<div class="filters">
  <span class="p-float-label">
    <p-dropdown
      [style]="{ 'min-width': '250px' }"
      [options]="portfolios"
      [(ngModel)]="reportsForm.portfolio"
      (ngModelChange)="onPortfolioChange()"
      [required]="true"
      [filter]="true"
      filterBy="portfolioCode,portfolioName"
      [dropdownIcon]="isLoadingPortfolio ? 'pi pi-spin pi-spinner' : ''"
      optionLabel="portfolioName"
    >
      <ng-template let-pf pTemplate="selectedItem">
        <span *ngIf="pf">
          {{ pf.portfolioName }} <strong>({{ pf.portfolioCode }})</strong>
        </span>
      </ng-template>
      <ng-template let-pf pTemplate="item">
        {{ pf.portfolioName }} <strong>({{ pf.portfolioCode }})</strong>
      </ng-template>
    </p-dropdown>
    <label><span style="color: red; font-size: larger">*</span> Portfolio</label>
  </span>

  <span class="p-float-label">
    <p-dropdown
      [style]="{ 'min-width': '250px' }"
      [options]="subPortfolios"
      [ngModel]="reportsForm.subPortfolio"
      (ngModelChange)="onSubPortfolioChange($event)"
      [required]="true"
      [filter]="true"
      filterBy="subPortfolioCode,subPortfolioName"
      [dropdownIcon]="isLoadingSubPortfolio ? 'pi pi-spin pi-spinner' : ''"
      optionLabel="subPortfolioName"
    >
      <ng-template let-spf pTemplate="selectedItem">
        <span *ngIf="spf">
          {{ spf.subPortfolioName }} <strong *ngIf="spf.subPortfolioCode">({{ spf.subPortfolioCode }})</strong>
        </span>
      </ng-template>
      <ng-template let-spf pTemplate="item">
        {{ spf.subPortfolioName }} <strong *ngIf="spf.subPortfolioCode">({{ spf.subPortfolioCode }})</strong>
      </ng-template>
    </p-dropdown>
    <label><span style="color: red; font-size: larger">*</span> Sub-Portfolio</label>
  </span>

  <div class="report-type-export">
    <p-selectButton
      [options]="reportsForm.reportOptions"
      [(ngModel)]="reportsForm.reportExport"
      optionLabel="label"
      optionValue="value"
      optionDisabled="disabled"
      [allowEmpty]="false"
    ></p-selectButton>
  </div>
</div>
