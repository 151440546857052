<p-fieldset [legend]="reportsForm.label" [toggleable]="true">
  <div class="filter-container">
    <div class="filters">
      <div class="incomeReportType">
        <p-selectButton [options]="reportOptions" [(ngModel)]="reportBy" optionLabel="label" optionValue="value" />
      </div>
      <div [ngClass]="!isReportByTaxYear ? 'hideSection' : ''" [style]="{ width: '16rem' }">
        <span class="p-float-label">
          <p-calendar
            [(ngModel)]="taxYearDate"
            view="year"
            [required]="true"
            dateFormat="30/06/yy"
            placeholder="30/06/YYYY"
            [showOnFocus]="false"
            [showIcon]="true"
          ></p-calendar>
          <label><span style="color: red; font-size: larger">*</span> Tax Year</label>
        </span>
      </div>
      <div [ngClass]="isReportByTaxYear ? 'hideSection' : ''" [style]="{ width: '16rem' }">
        <app-fy-calender label="Report Date Range" [isMandatory]="true" (onChange)="onChangeDates($event)" />
        <div class="flex mt-3">
          <div class="flex flex-wrap gap-3">
            <div class="flex align-items-center">
              <p-radioButton name="exDate" value="exDate" [(ngModel)]="dateField" inputId="exDate" />
              <label for="exDate" class="ml-2"> Ex Date </label>
            </div>

            <div class="flex align-items-center">
              <p-radioButton name="payDate" value="payDate" [(ngModel)]="dateField" inputId="payDate" />
              <label for="payDate" class="ml-2"> Pay Date </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-button
      styleClass="p-button-outlined"
      label="Generate Report"
      (onClick)="onClickGenerateReport()"
      [loading]="reportsForm.isLoading"
      [disabled]="!canGenerateReport"
    ></p-button>
  </div>
</p-fieldset>
