<p-fieldset [legend]="reportsForm.label" [toggleable]="true">
  <div class="filter-container">
    <div class="filters"></div>
    <p-button
      styleClass="p-button-outlined"
      label="Generate Report"
      (onClick)="onClickGenerateReport()"
      [loading]="reportsForm.isLoading"
      [disabled]="!canGenerateReport"
    ></p-button>
  </div>
</p-fieldset>
