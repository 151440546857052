import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsFormService } from './reports-form.service';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {
  constructor(private reportsFormService: ReportsFormService, private msgService: MessageService) {}

  ngOnDestroy(): void {
    this.notifSubscription?.unsubscribe();
  }

  notifSubscription!: Subscription;

  ngOnInit(): void {
    this.reportsFormService.getReportParams();
    this.notifSubscription = this.reportsFormService.showNotification.subscribe((req) => this.showNotification(req));
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  showNotification = (msg: Message) => {
    this.msgService.add(msg);
  };
}
