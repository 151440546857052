import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { BaseReportParams, IncomeExtractMapping, ReportCategory } from '../../reports.types';

@Component({
  selector: 'base-report',
  templateUrl: './base-report.component.html',
  styleUrls: ['./base-report.component.css']
})
export class BaseReportComponent implements OnInit {
  // Filters
  fromDate = '';
  toDate = '';

  constructor(private reportsFormService: ReportsFormService) {}

  ngOnInit(): void {}

  get reportsForm() {
    return this.reportsFormService;
  }

  get isUnrealised() {
    return this.reportsForm.category === ReportCategory.UNREALISED;
  }

  get canGenerateReport() {
    if (this.isUnrealised) {
      return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id;
    }
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate;
  }

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
  };

  onClickGenerateReport = () => {
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const url = `api/base-reports/${category}`;

    const isIncomeReport = category === ReportCategory.INCOME;
    const extractType = isIncomeReport ? IncomeExtractMapping[subCategory] : undefined;

    const params = {
      startDate: this.fromDate,
      endDate: this.toDate,
      subPortfolioId: this.reportsForm.subPortfolioId,
      portfolioId: this.reportsForm.portfolio?.id,
      extractType
    } as BaseReportParams;

    this.reportsFormService.generateBaseReport(url, params);
  };
}
