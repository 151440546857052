import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ISecurity, PriceSelection, UnRealisedParams, UnrealisedReport } from '../../reports.types';
import { SERVER_DATE_FORMAT } from 'src/app/app.constants';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'unrealised',
  templateUrl: './unrealised.component.html',
  styleUrls: ['./unrealised.component.css']
})
export class UnrealisedComponent implements OnInit {
  // Filters
  showSummary = true;
  priceDate!: Date;
  // noOfDays: number = 0;
  priceSelectionType: PriceSelection = 0;

  priceSelectionOptions = [
    { label: 'eSTM Cloud Prices', key: PriceSelection.STM_PRICE },
    { label: 'User Prices', key: PriceSelection.USER_PRICE },
    { label: 'User Prices First, then eSTM Cloud Prices', key: PriceSelection.USER_PRICE_THEN_STM_PRICE }
  ];

  isFetchingSecurities = false;
  securities: ISecurity[] = [];

  securityIds: string[] = [];

  subPortfolioSubcriber!: Subscription;
  portfolioSubcriber!: Subscription;

  constructor(private reportsFormService: ReportsFormService, private reportService: ReportsService) {
    this.portfolioSubcriber = this.reportsFormService.getPortfolioValue().subscribe((value) => {
      this.populatePriceDate();
    });
    this.subPortfolioSubcriber = this.reportsFormService.getSubPortfolioValue().subscribe((spf) => {
      spf && this.showSecurityFilter && this.fetchAllSecurities(spf.portfolioId, spf.id);
    });
  }

  ngOnInit(): void {
    this.populatePriceDate();
  }

  ngOnDestroy(): void {
    this.subPortfolioSubcriber.unsubscribe();
    this.portfolioSubcriber.unsubscribe();
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  get showSummaryOption() {
    const subCat = this.reportsForm.subCategory as UnrealisedReport;
    return [
      UnrealisedReport.SUMMARY,
      UnrealisedReport.NET_OFF_SUMMARY,
      UnrealisedReport.PORTFOLIO_VALUATION,
      UnrealisedReport.PORTFOLIO_SUMMARY,
      UnrealisedReport.COST_RECON_SUMMARY
    ].includes(subCat);
  }

  get hidePriceDate() {
    return this.reportsForm.subCategory === UnrealisedReport.PORTFOLIO_SUMMARY;
  }

  get canGenerateReport() {
    return Boolean(!this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.priceDate);
  }

  get showSecurityFilter() {
    const pathname = location.pathname;
    const allowedReports = [
      UnrealisedReport.DETAIL,
      UnrealisedReport.NET_OFF_DETAIL,
      UnrealisedReport.COST_RECON_DETAIL,
      UnrealisedReport.LOWER_COST_MKT_VALUE_DETAIL
    ];
    const canView = Boolean(allowedReports.find((rt) => pathname.endsWith(rt)));
    return canView;
  }

  fetchAllSecurities = (pfId: number, spfId?: number) => {
    if (this.showSecurityFilter && this.canGenerateReport) {
      this.isFetchingSecurities = true;
      this.securityIds = [];
      const body = {
        subPortfolioId: spfId
      } as UnRealisedParams;
      this.reportService.getAllUnrealisedSecurities(pfId, body).subscribe({
        next: (res) => {
          this.isFetchingSecurities = false;
          this.securities = res;
        },
        error: (err) => {
          this.isFetchingSecurities = false;
          this.securities = [];
        }
      });
    }
  };

  private populatePriceDate = () => {
    const processedUptoDate = this.reportsForm.portfolio?.processUptoDate;
    this.priceDate = processedUptoDate ? dayjs(processedUptoDate).toDate() : new Date();
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const isBaseReport = subCategory === UnrealisedReport.BASE_REPORT;
    const url = isBaseReport ? `api/base-reports/${category}/${portfolioId}` : `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      showSummary: this.showSummary,
      priceDate: dayjs(this.priceDate).format(SERVER_DATE_FORMAT),
      // noOfDaysForBackwardSearch: this.noOfDays,
      priceSelectionType: this.priceSelectionType,
      subPortfolioId: this.reportsForm.subPortfolioId,
      securityIds: this.securityIds
    } as UnRealisedParams;
    this.reportsFormService.generateFinalReport(url, body, isBaseReport);
  };
}
