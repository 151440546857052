import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { RegistryDataParams } from '../../reports.types';

@Component({
  selector: 'registry-data-report',
  templateUrl: './registry-data.component.html',
  styleUrls: ['./registry-data.component.css']
})
export class RegistryDataComponent implements OnInit {
  constructor(private reportsFormService: ReportsFormService) {}

  ngOnInit(): void {}

  get reportsForm() {
    return this.reportsFormService;
  }

  get canGenerateReport() {
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id;
  }

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const url = `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      subPortfolioId: this.reportsForm.subPortfolioId
    } as RegistryDataParams;
    this.reportsFormService.generateReport(url, body);
  };
}
