import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { CostChangesParams, CostChangesReport, ISecurity } from '../../reports.types';
import { ReportsService } from '../../reports.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cost-changes-report',
  templateUrl: './cost-changes.component.html',
  styleUrls: ['./cost-changes.component.css']
})
export class CostChangesComponent implements OnInit {
  // Filters
  fromDate = '';
  toDate = '';

  isFetchingSecurities = false;
  securities: ISecurity[] = [];

  securityIds: string[] = [];

  subPortfolioSubcriber!: Subscription;

  constructor(private reportsFormService: ReportsFormService, private reportService: ReportsService) {
    this.subPortfolioSubcriber = this.reportsFormService.getSubPortfolioValue().subscribe((spf) => {
      spf && this.showSecurityFilter && this.fetchAllSecurities(spf.portfolioId, spf.id);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subPortfolioSubcriber.unsubscribe();
  }

  get reportsForm() {
    return this.reportsFormService;
  }

  get canGenerateReport() {
    return Boolean(!this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate);
  }

  get showSecurityFilter() {
    const pathname = location.pathname;
    const allowedReports = [CostChangesReport.DETAIL];
    const canView = Boolean(allowedReports.find((rt) => pathname.endsWith(rt)));
    return canView;
  }

  fetchAllSecurities = (pfId: number, spfId?: number) => {
    if (this.showSecurityFilter && this.canGenerateReport) {
      this.isFetchingSecurities = true;
      this.securityIds = [];
      const body = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subPortfolioId: spfId
      } as CostChangesParams;
      this.reportService.getAllCCSecurities(pfId, body).subscribe({
        next: (res) => {
          this.isFetchingSecurities = false;
          this.securities = res;
        },
        error: (err) => {
          this.isFetchingSecurities = false;
          this.securities = [];
        }
      });
    }
  };

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.fetchAllSecurities(this.reportsForm.portfolio?.id, this.reportsForm.subPortfolioId);
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const url = `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      startDate: this.fromDate,
      endDate: this.toDate,
      subPortfolioId: this.reportsForm.subPortfolioId,
      securityIds: this.securityIds
    } as CostChangesParams;
    this.reportsFormService.generateReport(url, body);
  };
}
