import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageModule } from 'primeng/message';
import { FieldsetModule } from 'primeng/fieldset';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonSharedModule } from '../common/shared.module';

import { ReportsComponent } from './reports.component';
import {
  ActivityReport,
  CgtScheduleReport,
  CostChangesReport,
  IncomeReport,
  RealisedReport,
  RegistryDataReport,
  ReportCategory,
  UnrealisedReport
} from './reports.types';
import { UnrealisedComponent } from './report-content/unrealised/unrealised.component';
import { RealisedComponent } from './report-content/realised/realised.component';
import { ActivityComponent } from './report-content/activity/activity.component';
import { IncomeComponent } from './report-content/income/income.component';
import { BaseReportComponent } from './report-content/base-report/base-report.component';
import { CostChangesComponent } from './report-content/cost-changes/cost-changes.component';
import { RegistryDataComponent } from './report-content/registry-data/registry-data.component';

export const ReportsRoutes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    children: [
      { path: '', redirectTo: ReportCategory.UNREALISED, pathMatch: 'full' },
      {
        path: ReportCategory.UNREALISED,
        children: [
          { path: '', redirectTo: UnrealisedReport.SUMMARY, pathMatch: 'full' },
          { path: UnrealisedReport.SUMMARY, component: UnrealisedComponent },
          { path: UnrealisedReport.DETAIL, component: UnrealisedComponent },
          { path: UnrealisedReport.NET_OFF_DETAIL, component: UnrealisedComponent },
          { path: UnrealisedReport.NET_OFF_SUMMARY, component: UnrealisedComponent },
          { path: UnrealisedReport.PORTFOLIO_VALUATION, component: UnrealisedComponent },
          { path: UnrealisedReport.PORTFOLIO_SUMMARY, component: UnrealisedComponent },
          { path: UnrealisedReport.COST_RECON_DETAIL, component: UnrealisedComponent },
          { path: UnrealisedReport.COST_RECON_SUMMARY, component: UnrealisedComponent },
          { path: UnrealisedReport.LOWER_COST_MKT_VALUE_DETAIL, component: UnrealisedComponent },
          { path: UnrealisedReport.LOWER_COST_MKT_VALUE_SUMMARY, component: UnrealisedComponent },
          { path: UnrealisedReport.BASE_REPORT, component: UnrealisedComponent }
        ]
      },
      {
        path: ReportCategory.REALISED,
        children: [
          { path: '', redirectTo: RealisedReport.SUMMARY, pathMatch: 'full' },
          { path: RealisedReport.SUMMARY, component: RealisedComponent },
          { path: RealisedReport.DETAIL, component: RealisedComponent },
          { path: RealisedReport.NET_OFF_DETAIL, component: RealisedComponent },
          { path: RealisedReport.NET_OFF_SUMMARY, component: RealisedComponent },
          { path: RealisedReport.COST_RECON_DETAIL, component: RealisedComponent },
          { path: RealisedReport.COST_RECON_SUMMARY, component: RealisedComponent },
          { path: RealisedReport.BASE_REPORT, component: BaseReportComponent }
        ]
      },
      {
        path: ReportCategory.CGT_SCHEDULE,
        children: [
          { path: '', redirectTo: CgtScheduleReport.CGT_SCHEDULE_DETAIL, pathMatch: 'full' },
          { path: CgtScheduleReport.CGT_SCHEDULE_DETAIL, component: RealisedComponent },
          { path: CgtScheduleReport.CGT_SCHEDULE_SUMMARY, component: RealisedComponent }
        ]
      },
      {
        path: ReportCategory.ACTIVITY,
        children: [
          { path: '', redirectTo: ActivityReport.TRANSACTION, pathMatch: 'full' },
          { path: ActivityReport.TRANSACTION, component: ActivityComponent }
        ]
      },
      {
        path: ReportCategory.INCOME,
        children: [
          { path: '', redirectTo: IncomeReport.COMPANY, pathMatch: 'full' },
          { path: IncomeReport.COMPANY, component: IncomeComponent },
          { path: IncomeReport.TRUST, component: IncomeComponent },
          { path: IncomeReport.BASE_REPORT_BY_TAX_YEAR, component: BaseReportComponent }
        ]
      },
      {
        path: ReportCategory.COST_CHANGES,
        children: [
          { path: '', redirectTo: CostChangesReport.SUMMARY, pathMatch: 'full' },
          { path: CostChangesReport.SUMMARY, component: CostChangesComponent },
          { path: CostChangesReport.DETAIL, component: CostChangesComponent }
        ]
      },
      {
        path: ReportCategory.REGISTRY_DATA,
        children: [
          { path: '', redirectTo: RegistryDataReport.HOLDING, pathMatch: 'full' },
          { path: RegistryDataReport.HOLDING, component: RegistryDataComponent },
          { path: RegistryDataReport.REGISTRY_QTY_RECON, component: RegistryDataComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(ReportsRoutes),
    CommonSharedModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    InputNumberModule,
    RadioButtonModule,
    MessageModule,
    FieldsetModule,
    SelectButtonModule,
    MultiSelectModule
  ],
  exports: [RouterModule],
  declarations: [
    BaseReportComponent,
    ActivityComponent,
    IncomeComponent,
    RealisedComponent,
    UnrealisedComponent,
    CostChangesComponent,
    RegistryDataComponent
  ]
})
export class ReportsRoutingModule {}
