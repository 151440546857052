import { Injectable, EventEmitter } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { IPortfolio } from '../dashboard/portfolios/portfolios.component.types';
import { ISubPortfolio } from '../dashboard/sub-portfolios/sub-portfolios.component.types';
import { ReportsService } from './reports.service';
import {
  IncomeReport,
  RealisedReport,
  ReportCategory,
  ReportMenuEvent,
  ReportRequestBody,
  ReportSubCategory,
  UnrealisedReport
} from './reports.types';
import { fetchErrorMsg, getNotificationData } from './reports.utilities';
import { reportMenuTitles } from './report-menu-types';
import { Message } from 'primeng/api';

@Injectable()
export class ReportsFormService {
  isLoading = false;
  // Filters
  portfolio!: IPortfolio;
  subPortfolio!: ISubPortfolio;

  portfolioValue!: BehaviorSubject<IPortfolio | undefined>;
  subPortfolioValue!: BehaviorSubject<ISubPortfolio | undefined>;

  // Menu
  category!: ReportCategory;
  subCategory!: ReportSubCategory;

  selectedReport!: string;

  reportExport = 'xlsx';
  reportOptions: { label: string; value: string; disabled?: boolean }[] = [];

  // Value of the event emitter
  public showNotification = new EventEmitter<Message>();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private reportsService: ReportsService) {
    this.portfolioValue = new BehaviorSubject<IPortfolio | undefined>(undefined);
    this.subPortfolioValue = new BehaviorSubject<ISubPortfolio | undefined>(undefined);

    router.events.subscribe((event) => {
      // console.log('ReportsFormService -> constructor');
      if (event instanceof NavigationEnd) {
        // On route change, identify report params
        this.getReportParams();
      }
    });
  }

  ngOnInit(): void {}

  getPortfolioValue(): Observable<IPortfolio | undefined> {
    return this.portfolioValue.asObservable();
  }

  setPortfolioValue(portfolio: IPortfolio | undefined): void {
    this.portfolioValue.next(portfolio);
  }

  getSubPortfolioValue(): Observable<ISubPortfolio | undefined> {
    return this.subPortfolioValue.asObservable();
  }

  setSubPortfolioValue(subPortfolio: ISubPortfolio | undefined): void {
    this.subPortfolioValue.next(subPortfolio);
  }

  get subPortfolioId() {
    const subPortfolioId = this.subPortfolio?.id;
    return subPortfolioId !== Number.MIN_VALUE ? subPortfolioId || undefined : undefined;
  }

  get label() {
    const category = this.category;
    const subCategory = this.subCategory;

    return reportMenuTitles[`${category}_${subCategory}`];
  }

  getReportParams = () => {
    const pathname = location.pathname;
    const pathPrefix = '/reports/';
    const reportPathArr = pathname?.replace(pathPrefix, '')?.split('/') || [];
    const [category, subCategory] = reportPathArr;

    this.category = category as ReportCategory;
    this.subCategory = subCategory as ReportSubCategory;

    this.selectedReport = `${category}_${subCategory}`;
    this.setReportExportTypes();
  };

  onSelectReport = ({ category, subCategory }: ReportMenuEvent) => {
    // console.log('ReportsFormService -> onSelectReport');
    this.category = category;
    this.subCategory = subCategory;
    this.selectedReport = `${category}_${subCategory}`;
    this.setReportExportTypes();
  };

  generateFinalReport = (url: string, body: ReportRequestBody, isBaseReport: boolean = false) => {
    if (isBaseReport) {
      const method = 'post';
      return this.generateBaseReport(url, body, method);
    } else {
      return this.generateReport(url, body);
    }
  };

  generateReport = (url: string, body: ReportRequestBody) => {
    this.isLoading = true;
    const report = this.subCategory;
    const params = { type: this.reportExport };
    this.reportsService._generateReport({ url, params, body }).subscribe({
      next: () => this.notificationDetails(report),
      error: (err) => this.notificationDetails(report, fetchErrorMsg(err))
    });
  };

  generateBaseReport = (url: string, params: ReportRequestBody, method?: string) => {
    this.isLoading = true;
    const baseReport = `${this.category} ${this.subCategory}`;
    this.reportsService._generateBaseReport({ url, method, params }).subscribe({
      next: (res: HttpResponse<Blob>) => {
        const contentDisposition = res.headers.get('content-disposition');
        const fileName = contentDisposition?.split(';')[1].split('filename')[1].split('=')[1].trim();
        this.isLoading = false;
        const blobParts = res.body ? [res.body] : [];
        const blob = new Blob(blobParts, { type: 'application/octet-stream' });
        saveAs(blob, fileName);
        this.notificationDetails(baseReport);
      },
      error: async (err) => {
        const message = JSON.parse(await err?.error?.text())?.message;
        const errObj = { error: { message } };
        this.notificationDetails(baseReport, fetchErrorMsg(errObj));
      }
    });
  };

  updateQueryParams = () => {
    const { portfolioId, subPortfolioId } = this.activatedRoute.snapshot.queryParams;
    const sp = this.subPortfolio?.id === Number.MIN_VALUE ? undefined : this.subPortfolio?.id || subPortfolioId;
    const data = { portfolioId: this.portfolio?.id || portfolioId, subPortfolioId: sp };
    this.router.navigate([location.pathname], {
      queryParams: data
    });
  };

  private notificationDetails = (selectedReport: string, error = '') => {
    this.isLoading = false;
    const msgBody = getNotificationData(selectedReport, error);
    this.showNotficationToast(msgBody);
  };

  showNotficationToast = (msgBody: Message) => {
    this.showNotification.emit(msgBody);
  };

  private isPdfDisabled = () => {
    const nonPdfReports = [UnrealisedReport.BASE_REPORT, RealisedReport.BASE_REPORT, IncomeReport.BASE_REPORT_BY_TAX_YEAR] as ReportSubCategory[];
    const isDisabled = nonPdfReports.includes(this.subCategory);
    this.reportExport = isDisabled ? 'xlsx' : this.reportExport;
    return isDisabled;
  };

  setReportExportTypes = () => {
    this.reportOptions = [
      { label: 'PDF', value: 'pdf', disabled: this.isPdfDisabled() },
      { label: 'XLSX', value: 'xlsx' }
      // { name: 'Email', value: 'email' }
    ];
  };
}
