import { Component, OnInit } from '@angular/core';
import { ReportsFormService } from '../../reports-form.service';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { TransactionActivityParams } from '../../reports.types';

@Component({
  selector: 'activity-report',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  // Filters
  fromDate = '';
  toDate = '';

  constructor(private reportsFormService: ReportsFormService) {}

  ngOnInit(): void {}

  get reportsForm() {
    return this.reportsFormService;
  }

  get canGenerateReport() {
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate;
  }

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const url = `api/reports/activity/transaction-report/${portfolioId}`;
    const body = {
      startDate: this.fromDate,
      endDate: this.toDate,
      subPortfolioId: this.reportsForm.subPortfolioId
    } as TransactionActivityParams;
    this.reportsFormService.generateReport(url, body);
  };
}
