import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { ReportsFormService } from '../../reports-form.service';
import { IncomeParams } from '../../reports.types';
import { getTaxYearDates } from 'src/app/common/calender/calender.utilities';
import { SERVER_DATE_FORMAT } from 'src/app/app.constants';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { IncomeReportDateType } from './income.types';

@Component({
  selector: 'income-report',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css']
})
export class IncomeComponent implements OnInit {
  // Filters
  reportBy: string = 'taxYear';
  taxYearDate: Date | string = '';

  fromDate = '';
  toDate = '';
  dateField: IncomeReportDateType = IncomeReportDateType.exDate;

  portfolioSubcriber!: Subscription;

  reportOptions: any[] = [
    { label: 'By Tax Year', value: 'taxYear' },
    { label: 'By Date Range', value: 'dateRange' }
  ];

  constructor(private reportsFormService: ReportsFormService) {
    this.portfolioSubcriber = this.reportsFormService.getPortfolioValue().subscribe((value) => {
      this.populateTaxYearDate();
    });
  }

  ngOnInit(): void {
    this.populateTaxYearDate();
  }

  ngOnDestroy(): void {
    this.portfolioSubcriber.unsubscribe();
  }

  private populateTaxYearDate = () => {
    const processedUptoDate = this.reportsForm.portfolio?.processUptoDate;
    const { toDate } = getTaxYearDates(processedUptoDate);
    this.taxYearDate = toDate.toDate();
  };

  get reportsForm() {
    return this.reportsFormService;
  }

  get canGenerateReport() {
    return !this.reportsForm.isLoading && this.reportsForm.portfolio?.id && this.fromDate && this.toDate;
  }

  get isReportByTaxYear() {
    return this.reportBy === 'taxYear';
  }

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
  };

  onClickGenerateReport = () => {
    const portfolioId = this.reportsForm.portfolio?.id;
    const category = this.reportsForm.category;
    const subCategory = this.reportsForm.subCategory;
    const url = `api/reports/${category}/${subCategory}/${portfolioId}`;
    const body = {
      isReportByTaxYear: this.isReportByTaxYear,
      taxYear: dayjs(this.taxYearDate).get('year'),
      startDate: this.fromDate,
      endDate: this.toDate,
      dateField: this.dateField,
      subPortfolioId: this.reportsForm.subPortfolioId
    } as IncomeParams;
    this.reportsFormService.generateReport(url, body);
  };
}
