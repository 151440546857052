<div class="page-container">
  <report-filters />
  <div class="p-fluid container row">
    <report-menu [category]="reportsForm.category" [subCategory]="reportsForm.subCategory" (onMenuClick)="reportsForm.onSelectReport($event)" />
    <p-divider layout="vertical"></p-divider>
    <div class="main-content">
      <div *ngIf="reportsForm.portfolio">
        <router-outlet></router-outlet>
      </div>
      <p-message *ngIf="!reportsForm.portfolio" severity="info" text="Portfolio must be selected to generate report"></p-message>
    </div>
  </div>
</div>
<p-blockUI [blocked]="reportsForm.isLoading"></p-blockUI>
