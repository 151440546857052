<p-fieldset [legend]="reportsForm.label" [toggleable]="true">
  <div class="filter-container">
    <div class="filters-wrapper">
      <div class="filters">
        <span class="p-float-label" *ngIf="!hidePriceDate">
          <p-calendar
            [style]="{ width: 'fit-content' }"
            [(ngModel)]="priceDate"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY"
            [showOnFocus]="false"
            [showIcon]="true"
          ></p-calendar>
          <label>
            <span style="color: red; font-size: larger">*</span>
            Price Date
          </label>
        </span>
        <!-- <div class="flex align-items-center gap-1">
          <span class="p-float-label">
            <p-inputNumber
              [min]="0"
              [allowEmpty]="false"
              [style]="{ 'max-width': '12rem', width: '100%' }"
              inputId="integeronly"
              [(ngModel)]="noOfDays"
            >
            </p-inputNumber>
            <label>
              <span style="color: red; font-size: larger">*</span>
              Number of days
            </label>
          </span>
          to search backwards from the price date for a valid user price
        </div> -->
        <div class="flex flex-wrap gap-3">
          <div *ngFor="let priceOption of priceSelectionOptions">
            <p-radioButton [inputId]="priceOption.label" [value]="priceOption.key" [(ngModel)]="priceSelectionType"></p-radioButton>
            <label [for]="priceOption.label" class="ml-2">{{ priceOption.label }}</label>
          </div>
        </div>
        <div *ngIf="showSummaryOption" class="flex align-items-center gap-1">
          <p-checkbox [(ngModel)]="showSummary" [binary]="true" inputId="showSummary"></p-checkbox>
          <label for="showSummary">Show summary reports at underlying level</label>
        </div>
        <div class="flex align-items-center gap-2">
          <span class="p-float-label security-wrapper" *ngIf="showSecurityFilter">
            <p-multiSelect
              class="security-filter"
              placeholder="Securities"
              [options]="securities"
              [(ngModel)]="securityIds"
              [filter]="true"
              filterBy="securityCode,exchangeCode,securityName"
              optionLabel="securityName"
              optionValue="securityId"
              [dropdownIcon]="isFetchingSecurities ? 'pi pi-spin pi-spinner' : ''"
              display="chip"
              [showClear]="true"
              [disabled]="!canGenerateReport"
              [resetFilterOnHide]="true"
            >
              <ng-template let-sec pTemplate="selectedItem">
                <span *ngIf="sec">
                  <strong>{{ sec.securityCode }}.{{ sec.exchangeCode }}</strong> - {{ sec.securityName }}
                </span>
              </ng-template>
              <ng-template let-sec pTemplate="item">
                <span class="dropdown-items">
                  <strong>{{ sec.securityCode }}.{{ sec.exchangeCode }}</strong> - {{ sec.securityName }}
                </span>
              </ng-template>
            </p-multiSelect>
            <label>Securities</label>
          </span>
        </div>
      </div>
    </div>

    <p-button
      styleClass="p-button-outlined"
      label="Generate Report"
      (onClick)="onClickGenerateReport()"
      [loading]="reportsForm.isLoading"
      [disabled]="!canGenerateReport"
    ></p-button>
  </div>
</p-fieldset>
