import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { MessageModule } from 'primeng/message';
import { CommonSharedModule } from '../common/shared.module';
import { BlockUIModule } from 'primeng/blockui';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MessageService } from 'primeng/api';

import { ReportsRoutingModule } from './reports.routing.module';
import { ReportsFormService } from './reports-form.service';
import { ReportsService } from './reports.service';
import { ReportsComponent } from './reports.component';
import { ReportFiltersComponent } from './report-filters/report-filters.component';
import { ReportMenuComponent } from './report-menu/report-menu.component';

@NgModule({
  imports: [
    CommonModule,
    CommonSharedModule,
    ButtonModule,
    FormsModule,
    DropdownModule,
    RadioButtonModule,
    PanelMenuModule,
    DividerModule,
    FieldsetModule,
    MessageModule,
    BlockUIModule,
    SelectButtonModule,
    ReportsRoutingModule
  ],
  declarations: [ReportsComponent, ReportFiltersComponent, ReportMenuComponent],
  providers: [MessageService, ReportsFormService, ReportsService]
})
export class ReportsModule {}
